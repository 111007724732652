.primary-solid {
  background-color: $theme-color;
  border-color: $theme-color;
  color: white;
  min-width: 320px;
  font-weight: 600;
  font-size: 14px;
  height: unset;
  border-radius: 16px;
  padding: 10px 0;
  border-radius: 8px;
  min-width: 124px;
  font-family: "Poppins", sans-serif;
  &:hover {
    color: white !important;
  }
}
button:disabled {
  &:hover {
    color: #b8b8b8 !important;
    border: 1px solid #b8b8b8 !important;
  }
}
.login-btn {
  min-width: 320px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 16px;
  padding: 10px 0;
}
h2 {
  margin: 0;
}
button {
  &:focus-visible {
    outline: none !important;
  }
}
.ant-layout-header {
  background-color: rgb(255, 255, 255);
  padding: 20px 30px;
}
.primary-outline {
  display: flex;
  align-items: center;
  border: 1px solid $theme-color;
  min-width: 124px;
  padding: 10px 0;
  justify-content: center;
  &:hover {
    color: $theme-color !important;
    border: 1px solid $theme-color !important;
  }
  img {
    margin-right: 10px;
  }
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: $theme-color;
}
.ant-switch.ant-switch-checked {
  background-color: $theme-color;
}

.ant-table-column-sorter-inner {
  .active {
    color: #272724 !important;
  }
}
.ant-btn {
  height: unset;
  padding: 8px 15px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.font {
  &-2 {
    font-size: 16px;
  }
}

.m {
  &-0 {
    margin: 0px !important;
  }
  &b {
    &-0 {
      margin-bottom: 0px !important;
    }
    &-2 {
      margin-bottom: 16px !important;
    }
    &-3 {
      margin-bottom: 24px !important;
    }
  }
  &l {
    &-0 {
      margin-left: 0px !important;
    }
    &-1 {
      margin-left: 8px !important;
    }
    &-2 {
      margin-left: 16px !important;
    }
    &-3 {
      margin-left: 24px !important;
    }
    &-4 {
      margin-left: 32px !important;
    }
  }
  &r {
    &-0 {
      margin-right: 0px !important;
    }
    &-1 {
      margin-right: 8px !important;
    }
    &-2 {
      margin-right: 16px !important;
    }
    &-3 {
      margin-right: 24px !important;
    }
    &-5 {
      margin-right: 40px !important;
    }
  }
  &t {
    &-0 {
      margin-top: 0px !important;
    }
    &-1 {
      margin-top: 8px !important;
    }
    &-2 {
      margin-top: 16px !important;
    }
    &-3 {
      margin-top: 24px !important;
    }
  }
}

.p {
  &t {
    &-0 {
      padding-top: 0 !important;
    }
  }
  &-2 {
    padding: 16px !important;
  }
}

.w {
  &-12 {
    width: 12% !important;
  }
  &-25 {
    width: 25% !important;
  }
  &-100 {
    width: 100% !important;
  }
}

.h {
  &-100 {
    height: 100% !important;
  }
  &-100 {
    height: 100px !important;
  }
  &-149 {
    height: 149px !important;
  }
}

.d {
  &-flex {
    display: flex !important;
  }
  &-block {
    display: block !important;
  }
  &-none {
    display: none !important;
  }
}

.flex {
  &-column {
    flex-direction: column;
  }
}

.visibility {
  &-hidden {
    visibility: hidden;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
  &-baseline {
    align-items: baseline;
  }
}

.justify-content {
  &-end {
    justify-content: end;
  }
  &-between {
    justify-content: space-between !important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.custom {
  &-height {
    &-100 {
      height: 100px !important;
    }
  }
  &-width {
    &-150 {
      width: 150px !important;
    }
    &-200 {
      width: 200px !important;
    }
    &-380 {
      width: 380px !important;
    }
    &-320 {
      width: 320px !important;
    }
  }
}

.no-form-item {
  .ant-form-item-control {
    display: none;
  }
}

.notification {
  min-width: 160px;
}

.errorMessage {
  color: #ff0000;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
}

.no-errorMessage {
  height: 18px;
}

.mix-blend-mode {
  mix-blend-mode: multiply;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
}

.ant-picker {
  width: 100%;
  height: 40px;
  input {
    &:hover {
      border: none !important;
    }
  }
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid transparent;
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: $theme-color !important;
  }
  .not-selected {
    background-color: white !important;
    color: black !important;
  }
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-now-btn {
  color: $theme-color;
}
.ant-btn-primary {
  background-color: $theme-color;
  &:hover,
  &:active {
    background-color: $theme-color !important;
  }
}
.ant-picker-now-btn {
  color: $theme-color;
  &:hover,
  &:active {
    color: $theme-color !important;
  }
}

.no-background {
  background: transparent !important;
}

.d {
  &-flex {
    display: flex;
  }
}

.flex {
  &-column {
    flex-direction: column !important;
  }
}

.ant-select-arrow {
  color: $theme-color !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.authenticate-btn {
  margin-right: 20px;
  align-items: center;
  display: flex;
  img {
    margin-right: 10px;
  }
}

.group_container {
  display: "flex";
  flex-direction: "column";
  margin-top: "40px";
  margin-left: "40px";
}

.ant-avatar-group {
  .ant-avatar {
    &:last-child {
      background-color: $theme-color !important;
    }
  }
}

.error {
  color: red;
  &-setting {
    font-size: 10px;
  }
}
.form-outer {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 61px 0px rgba(0, 0, 0, 0.1);
  padding: 47px 55px;
}

.card-no-body {
  .ant-card-head {
    border-radius: 6px !important;
  }
  .ant-card-body {
    display: none;
  }
}
.password-input {
  width: 100%;
  margin-bottom: 10px;
}

.password-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
}

.password-strength {
  color: #999;
  font-size: 14px;
}

.password-strength.strong {
  color: green;
}

.password-strength.medium {
  color: orange;
}

.password-strength.weak {
  color: red;
}
.box-shadow {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.21);
}
.ticker-box {
  padding: 13px 14px;
  height: 65px;
  @media (max-width: 1400px) {
    padding: 10px 14px;
  }
}
.ticker-text-logo {
  display: flex;
  margin: 0;
  align-items: center;
  flex-wrap: wrap;
  img {
    margin-right: 39px;
  }
}
.action {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0px 10px;
    cursor: pointer;
    @media (max-width: 1400px) {
      margin: 6px 5px;
      width: 20px;
    }
  }
  .eye-icon {
    margin-top: 3px;
    @media (max-width: 1400px) {
      width: 20px;
      margin-top: 9px;
    }
  }
}

.ticker-title {
  font-size: 20px;
  font-weight: 300;
}

.disabled-row {
  background-color: #ffeaea;
  &:hover {
    .ant-table-cell-row-hover {
      background-color: #ffeaea !important;
    }
  }
}

.data-menu-item {
  .ant-menu-submenu-title {
    path {
      fill: #4a3567 !important;
    }
    .ant-menu-title-content {
      color: #4a3567 !important;
    }
  }
}

.quill {
  .ql-toolbar {
    display: flex;
  }
}

.title-limit {
  font-size: 9px;
  margin-top: 20px;
}

.weight {
  &-boldest {
    font-weight: 900;
  }
}

.live {
  &-user {
    border: 2px solid green;
  }
  &-status {
    height: 20px;
    position: absolute;
    top: 33px;
    left: 34px;
  }
}

.avatar {
  &-border {
    border: 1px solid #a7a7a7;
  }
}

.scroll {
  overflow-y: scroll;
}

.metric-preview {
  margin-top: 2px;
  cursor: pointer;
  margin-right: 11px;
}

.leaderboard-preview {
  margin-top: 2px;
  cursor: pointer;
  margin-right: 10px;
  color: #868685;
}

.outline {
  &-none {
    outline: none;
  }
}
