.marquee-header {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  box-shadow: #d6d6d683 0px 2px 8px;
  .marquee-inner-left {
    border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    padding: 13px 18px;
    height: 40px;
    width: 100%;
    svg {
      width: 25px;
    }
    h2 {
      font-size: 14px;
      font-weight: 900;
      color: white;
      padding-left: 14px;
      line-height: 20px;
      text-transform: uppercase;
      text-align: left;
    }
  }
  .marquee-inner-right {
    display: flex;
    width: 100%;
    align-items: center;
    height: 65px;
    .marque-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 65px;
      h2 {
        font-size: 14px;
        font-weight: 900;
        color: #999;
        line-height: 20px;
        margin: 0;
        margin-right: 20px;
      }
      .marquee {
        height: 65px;
      }
    }
  }
}
