.ant-modal {
  .ant-modal-content {
    border-radius: 18px;
    padding: 30px 0;
    .para {
      text-align: left;
      font-size: 12px;
    }
  }
  .ant-modal-footer {
    padding: 0 30px;
    margin: 0;
  }
  .ant-modal-close-x {
    color: #272724;
    font-size: 20px;
  }
  .ant-modal-header {
    .ant-modal-title {
      font-size: 22px;
      color: #272725;
      text-align: center;
      font-family: "cera_probold";
    }
  }
  label {
    color: #404040;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-select {
    .ant-select-selector {
      text-align: left;
    }
    &:hover {
      .ant-select-selector {
        border-color: #e0e2e7;
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
  .ant-select-selector {
    background-color: #ffffff !important;
    height: 40px !important;
    &:hover {
      border: 1px solid #e0e2e7;
    }
    .ant-select-selection-placeholder {
      line-height: 37px;
      font-size: 14px;
      font-weight: 400;
      color: #858d9d;
    }
  }
  input {
    background-color: #ffffff;
    border: 1px solid #e0e2e7;
    height: 40px !important;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.88);
    &:hover {
      border: 1px solid #e0e2e7;
    }
  }
  .ant-radio-wrapper .ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    background-color: transparent;
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $theme-color;
    background-color: $theme-color;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    border-color: $theme-color;
    background-color: $theme-color;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: $theme-color;
  }
  .ant-radio-wrapper,
  :where(.css-dev-only-do-not-override-1m62vyb).ant-radio-wrapper:hover
    .ant-radio-inner {
    border-color: $theme-color;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $theme-color;
    background-color: $theme-color;
  }
  .footer-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    button {
      span {
        width: 100%;
      }
    }
  }
  .ant-steps {
    .ant-steps-item-tail::after {
      height: 5px;
      border-radius: 5px;
    }
    .ant-steps-item-process .ant-steps-item-icon {
      background-color: #272724;
      border-color: #272724;
    }
    .ant-progress-circle-path {
      stroke: #272724 !important;
    }
    border-bottom: 1px solid #d9dbe9;
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: $theme-color;
        border-color: $theme-color;
        svg {
          color: rgb(255, 255, 255);
        }
      }
      .ant-steps-item-container {
        .ant-steps-item-tail::after {
          background-color: #272724;
        }
      }
    }
  }
  .team-step-content {
    .credit-list-scroll {
      background-color: #f9f9fc;
      border-radius: 10px;
      height: 200px;
      overflow: scroll;
      .credit-list {
        border-bottom: 1px solid #e4e4e4;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        position: relative;
      }
      .close {
        position: absolute;
        right: 30px;
        top: 8px;
        font-weight: 800;
        font-size: 14px;
      }
    }
    .kwip-credit-scroll {
      height: 194px !important;
    }
  }
  .steps-content {
    padding: 0 30px;
    line-height: 260px;
    text-align: center;
    margin-top: 16px;
    .plus-icon {
      display: flex;
      align-items: center;
    }
    .credit-list-scroll {
      background-color: #f9f9fc;
      border-radius: 10px;
      height: 200px;
      overflow: scroll;
      .credit-list {
        border-bottom: 1px solid #e4e4e4;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        position: relative;
      }
      .close {
        position: absolute;
        right: 30px;
        top: 8px;
        font-weight: 800;
        font-size: 14px;
      }
    }
    .kwip-credit-scroll {
      height: 260px !important;
    }
    .create-notify-list {
      background-color: #f9f9fc;
      border-radius: 10px;
      height: 260px;
      overflow: scroll;
      .notify-list-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-bottom: 1px solid #e0e2e7;
        .ant-avatar {
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #272725;
        }
      }
    }
    .ant-collapse {
      border: none;
      border-radius: 0;
      background-color: transparent;
      .ant-collapse-header-text {
        flex: unset !important;
      }
      .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header-text {
          font-weight: 700;
        }
        .ant-collapse-header {
          padding: 0;
        }
      }
      .ant-collapse-content {
        border-top: none !important;
      }
      .ant-space-item {
        margin-bottom: 6px;
      }
    }
  }
  .anticon-plus-circle {
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .notification-fields {
    background-color: #f9f9fc;
    border-radius: 10px;
    .notification-fields-list {
      height: 150px;
      overflow: clip scroll;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin: 0;
      border-bottom: 1px solid #d9dbe9;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #404040;
      .anticon.anticon-delete {
        color: #ff3535;
        cursor: pointer;
      }
    }
    .ant-row {
      padding: 10px 10px;
    }
    .ant-col {
      span {
        font-size: 14px;
        font-weight: 500;
        color: #404040;
      }
      // &:last-child {
      //   text-align: right;
      // }
      // &:first-child {
      //   text-align: left;
      // }
      text-align: left;
    }
    ::-webkit-scrollbar-track {
      background: #f9f9fc;
      border-radius: 50px;
    }
  }
  label {
    margin: 3px 0;
  }
}
.common-modal {
  .ant-modal-body {
    padding: 20px 30px;
    .profile-pic {
      background: whitesmoke;
      border-radius: 4px;
      height: 154px;
      width: 100%;
      border-radius: 50%;
      .editIcon {
        position: absolute;
        right: 12px;
        top: 32px;
        cursor: pointer;
        background: white;
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 50%;
        display: flex;
        align-items: center;
        align-items: center;
        justify-content: center;
      }
      .circle {
        position: absolute;
        border: 2px solid white;
        cursor: pointer;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
        bottom: 10px;
        margin: 0 auto;
        display: table;
        right: 0;
        left: 0;
        &.active {
          background: #35b32c !important;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50%;
      }
    }
    .member {
      background-color: #f9f9fc;
      border-radius: 4px;
      height: 180px;
      overflow: scroll;
      .member-list {
        justify-content: space-between;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e2e7;
        padding: 10px 10px;
        span {
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
        .ant-avatar {
          margin-left: 20px;
          margin-right: 5px;
        }
        .select-dropdown {
          .ant-select {
            width: 100%;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.add-data {
  background: black;
  padding: 10px 12px;
  color: white;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
  font-size: 12px;
}
.filter-criteria {
  max-height: 262px;
  overflow: scroll;
  padding-right: 10px;
}

.swiper-pagination {
  bottom: 0px;
  z-index: 999999;
  position: relative;
  .swiper-pagination-bullet-active {
    background-color: #000;
  }
}
.teams {
  .ant-modal-body {
    padding: 0;
  }
  .teams-row {
    margin-bottom: 26px;
    border-bottom: 1px solid #b0b0b0;
    .ant-col {
      border-right: 1px solid #b0b0b0;
      h2 {
        border-bottom: 1px solid #b0b0b0;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.32px;
        padding-bottom: 11px;
      }
    }

    .ant-collapse-content-box {
      padding-bottom: 0;
      padding-top: 0 !important;
      background-color: rgb(255, 255, 255);
    }
    .ant-collapse-header {
      padding: 0;
      background-color: rgb(255, 255, 255);
      .ant-collapse-expand-icon {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
  .team-list {
    height: 200px;
    overflow: scroll;
    h3 {
      display: flex;
      align-items: center;
      color: #272725;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.07px;
      border-bottom: 1px solid #e0e2e7;
      padding: 9px 14px;
      margin: 0;
      margin: 0;
      img {
        width: 25px;
        background: #d7f5e7;
        border-radius: 50%;
        text-align: center;
        line-height: 35px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: stretch;
        margin-right: 10px;
      }
    }
  }
}

.no-member {
  color: red;
  padding: 5px;
  font-size: 15px;
  text-align: center;
  display: block;
}

.add-more-filter {
  &:disabled:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.creditTo {
  &_type {
    margin: 0px 5px;
  }
  &_field {
    margin: 0px 5px;
  }
}

.template-row {
  .ant-form-item-label {
    padding: 0;
  }
}

.radio-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9dbe9;
  padding-bottom: 12px;
  padding: 13px;
  .ant-radio {
    margin-right: 15px;
  }
  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }

  .ant-checkbox-input {
    height: unset !important;
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    &:after {
      width: 5px;
      height: 9px;
    }
  }
  img {
    cursor: pointer;
    height: 18px;
  }
  label {
    color: #333;
    font-family: "cera_promedium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  .ant-checkbox-wrapper:hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-radius: 50%;
  }
}

.radio-list {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e0e2e7;
  background: #a6a6a68a;
  height: 175px;
  overflow-y: scroll;
  .ant-space {
    width: 100%;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.ticker-buttons {
  display: flex;
  border-radius: 5px;
  border: 1px solid #000;
  background: #f9f9fc;
  width: 215px;
  margin-right: 0;
  margin-left: auto;
  .ticker-buttons-item {
    padding: 13px 24px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.075px;
    cursor: pointer;
    &.active {
      background: #272725;
      color: #fff;
    }
  }
}
.content-name {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.logo-text-bar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.21);
  margin-bottom: 0px;
  .logo-box {
    display: flex;
    width: 70px;
    height: 50px;
    align-items: center;
    margin-left: 5px;
    padding: 0;
    img {
      height: 45px;
      width: 60px;
      padding: 5px;
    }
  }
  .text-box {
    border-radius: 0px 8px 8px 0px;
    background: #6f6fc7;
    width: 100%;
    color: white;
    border-radius: 0px 8px 8px 0px;
    background: #6f6fc7;
    width: 100%;
    font-family: "cera_promedium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
  }
}
.formatting {
  border-radius: 5px;
  background: #e9e9ed;
  padding: 6px 10px;
  display: flex;
  label {
    color: #404040;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
  }
  .ant-form-item-label {
    padding: 0;
  }
  .text-format {
    display: flex;
    margin-top: 28px;
    margin-right: 20px;
    .text-format-icon {
      border-radius: 3px;
      background: #eee;
      width: 47px;
      height: 33px;
      text-align: center;
      line-height: 33px;
      margin-right: 6px;
      cursor: pointer;
      color: #272725;
      font-family: "cera_promedium";
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0.23px;
    }
  }
  .ant-input-number {
    border-radius: 8px;
    border: 1px solid #e0e2e7;
    background: #f9f9fc;
    height: 40px;
    width: 100%;
    .ant-input-number-handler-wrap {
      display: none;
    }
    &.ant-input-number-focused {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
    input {
      border: 1px solid #e0e2e7;
      &:hover {
        border: 1px solid #e0e2e7;
      }
    }
  }
}

.background-color-item {
  height: 34px;
  width: 150px;
}

.ticker-image {
  border: 1px dashed;
  padding: 3px 5px 3px 5px;
  border-radius: 6px;
  margin-left: 8px;
  align-items: center;
  display: flex;
  span {
    height: 25px;
    width: 25px;
    margin-right: 4px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.ticker-modal {
  .ant-modal-body {
    padding: 0;
  }
  .time-collapse {
    border-radius: 18px;
    border: 1px solid #d9dee4;
    height: 85%;
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      margin: 20px;
    }
    .ant-collapse-item {
      &:last-child {
        .ant-collapse-header {
          border-bottom: none;
        }
      }
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
    .ant-collapse-header {
      border-bottom: 1px solid #e0e2e7;
      padding: 10px 20px !important;
      color: #272725;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.07px;
      align-items: center;
    }
  }
  .ticker-content {
    line-height: unset;
  }
}

.ticker-card {
  border-radius: 18px;
  border: 1px solid #d9dee4;
  margin-bottom: 35px;
  .ant-card-body {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-card-head {
    border-radius: 18px 18px 0px 0px;
    border: 1px solid #d9dee4 !important;
    background: #f2f4f7 !important;
    .ant-card-head-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.22px;
      text-transform: initial;
    }
  }
  .week-days {
    display: flex;
    justify-content: space-between;
    p {
      padding: 30px 19px;
      margin: 0;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.22px;
      width: 53px;
      cursor: pointer;
      &.active {
        background: #272725;
        color: #fff;
      }
      &:first-child {
        &.active {
          border-radius: 0px 0px 0px 17px;
        }
      }
      &:last-child {
        &.active {
          border-radius: 0px 0px 17px 0;
        }
      }
    }
  }
}

.heading-tab-btn {
  border-radius: 5px;
  border: 1px solid #000;
  background: #f9f9fc;
  display: flex;
  margin-bottom: 20px;
  span {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.075px;
    padding: 10px 23px;
    display: inline-block;
    width: 100px;
    &.active {
      background-color: rgb(0, 0, 0);
      color: rgb(255, 255, 255);
    }
  }
}

.time-slot {
  .time-slot-slide {
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    display: flex !important;
    justify-content: center;
    h3 {
      border-radius: 5px;
      border: 1px solid #d0d0d0;
      background: #f2f4f7;
      color: #272725;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.22px;
      width: 150px;
      padding: 10px 10px;
      text-align: center;
      justify-content: center;
      margin: 10px 10px;
      &.active {
        background-color: black;
        color: white;
      }
    }
  }
  ul.slick-dots.slick-dots-bottom {
    display: none !important;
  }
}

.prev-next-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  bottom: 0;
  top: 56px;
  position: absolute;
  width: 96%;
  margin: 0 auto;
  padding: 0px 12px;
  img {
    z-index: 9999;
  }
}

.weekly-time {
  h6 {
    margin: 0;
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
  }
  .week-time {
    border-bottom: 1px solid #e0e2e7;
    padding: 10px 20px !important;
  }
}

.end-date-picker {
  width: 200px;
  top: 10px;
  right: 40px;
}

.selected {
  background: black;
  border-radius: 50%;
  color: white;
  &:hover {
    background: black !important;
    color: white !important;
  }
}

.ticker-icon-div {
  height: 30px;
  width: 30px;
}

.ticker-container {
  .ant-radio-button-wrapper:hover {
    color: black !important;
  }

  .ant-radio-button-wrapper-checked {
    background: black;
    color: white;
    border-color: black !important;
  }

  .ant-radio-button-wrapper-checked:hover {
    color: white !important;
    border-color: black !important;
  }
}

.ticker-container-icons {
  img {
    height: 30px;
    width: 30px;
    border: 1px solid black;
    padding: 10px;
    margin: 4px;
  }
  .active_icon {
    background-color: #eee !important;
  }
}

.profile-pic {
  background: whitesmoke;
  border-radius: 4px;
  height: 154px;
  width: 100%;
  .editIcon {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    background: white;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
  }
  .deleteIcon {
    position: absolute;
    right: 12px;
    top: 56px;
    cursor: pointer;
    background: white;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
  }
  .sliderIcon {
    position: absolute;
    right: 12px;
    top: 80px;
    cursor: pointer;
    background: white;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
  }
  .circle {
    position: absolute;
    border: 2px solid white;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: black;
    bottom: 10px;
    margin: 0 auto;
    display: table;
    right: 0;
    left: 0;
    &.active {
      background: #35b32c !important;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.team-select {
  .ant-select {
    width: 100%;
  }
}

.team-step-content {
  padding: 0 30px;
  line-height: 260px;
  text-align: center;
  margin-top: 16px;
}

.member {
  background-color: #f9f9fc;
  border-radius: 4px;
  height: 180px;
  overflow: scroll;
  .member-list {
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e2e7;
    padding: 10px 10px;
    span {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .ant-avatar {
      margin-left: 20px;
      margin-right: 5px;
    }
    .select-dropdown {
      .ant-select {
        width: 100%;
        margin-left: 10px;
      }
    }
  }
}

.user {
  &-role {
    .ant-form-item-label {
      padding: 0px !important;
      margin-right: 5px;
    }
  }
}

.recognition-fields {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dedede;
}

.selected-ticker {
  box-shadow: 0px 1px 1px 3px black;
  padding: 2px;
  height: 57px;
  width: 57px;
}

.color-palet-container {
  box-shadow: 0px 1px 1px 3px black;
  padding: 2px;
  height: 45px;
  width: 45px;
  .color-palet {
    height: 43px;
    width: 43px;
    border: 1px solid black;
  }
}

.color-palet {
  height: 45px;
  width: 45px;
  border: 1px solid black;
}

.flagged {
  background: #ffbdbd !important;
}

.ticker-margin {
  margin: 0px 8px 8px 0px;
}

.ticker-icon {
  background: rgb(178, 178, 178);
  height: 45px;
  width: 45px;
  padding: 6px;
  display: flex;
  align-items: center;
  svg {
    height: 40px;
    width: 40px;
    margin: auto;
  }
}

.list-item-action {
  font-size: 18px;
}

.headline-icon-size {
  font-size: 20px;
}

.birthday_picker {
  span {
    &:first-child {
      .ant-select {
        width: 120px !important;
      }
    }
    &:last-child {
      .ant-select {
        width: 70px !important;
      }
    }
  }
}

.metric_value_label {
  margin-bottom: 8px !important;
  label {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  label ::after {
    content: "";
  }
}

.metric {
  &-container {
    padding: 10px 30px;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #272724 !important;
}

.credit-list-scroll {
  background-color: #f9f9fc;
  border-radius: 10px;
  height: 200px;
  overflow: scroll;
  .credit-list {
    border-bottom: 1px solid #e4e4e4;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    position: relative;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 8px;
    font-weight: 800;
    font-size: 14px;
  }
}
.kwip-credit-scroll {
  height: 194px !important;
}
